import Google from './google';
// import FbPixel from './facebook';

export const initTrackers = () => {
  Google.consentGranted();
  // FbPixel.init();
};

export const blockTrackers = () => {
  Google.consentDenied();
};

export const trigPageview = () => {
  Google.pageview();
  // FbPixel.pageview();
};