import React from 'react';
import { Link } from 'gatsby';
import styled, { css, keyframes } from 'styled-components';
import { darken, lighten } from 'polished';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import BREAKPOINTS from '../../constants/breakpoints';

/* button animations */
const btnRipple = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
`;

/* color styling */
const types = {
  /* -=-=-=-=-=-=-=- */
  primary:css`
    color: #fff;
    background-color: ${props =>  props.theme.colors.primary};
    border-color: ${props =>  props.theme.colors.primary};

    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: #fff;
      background-color: ${props =>  lighten(0.1, props.theme.colors.primary)};
      border-color: ${props =>  darken(0.1, props.theme.colors.primary)};
    }

    &:disabled,
    &.disabled {
      color: #fff;
      background-color: ${props =>  lighten(0.2, props.theme.colors.primary)};
      border-color: ${props =>  lighten(0.2, props.theme.colors.primary)};
    }

    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 0.3rem ${props =>  lighten(0.25, props.theme.colors.primary)};
    }
  `,
  /* -=-=-=-=-=-=-=- */
  outlineprimary:css`
    color: ${props =>  props.theme.colors.primary};
    border-color: ${props =>  props.theme.colors.primary};
    background-color: rgba(255,255,255,0.75);

    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: #fff;
      background-color: ${props =>  lighten(0.1, props.theme.colors.primary)};
      border-color: ${props =>  darken(0.1, props.theme.colors.primary)};
    }

    &:disabled,
    &.disabled {
      color: ${props =>  lighten(0.2, props.theme.colors.primary)};
      border-color: ${props =>  lighten(0.2, props.theme.colors.primary)};
      background-color: transparent;
    }

    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 0.3rem ${props =>  lighten(0.25, props.theme.colors.primary)};
    }
  `,
  /* -=-=-=-=-=-=-=- */
  secondary:css`
    color: #fff;
    background-color: ${props =>  props.theme.colors.secondary};
    border-color: ${props =>  props.theme.colors.secondary};

    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: #fff;
      background-color: ${props =>  lighten(0.1, props.theme.colors.secondary)};
      border-color: ${props =>  darken(0.1, props.theme.colors.secondary)};
    }

    &:disabled,
    &.disabled {
      color: #fff;
      background-color: ${props =>  lighten(0.2, props.theme.colors.secondary)};
      border-color: ${props =>  lighten(0.2, props.theme.colors.secondary)};
    }


    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 0.3rem ${props =>  lighten(0.25, props.theme.colors.secondary)};
    }
  `,
  /* -=-=-=-=-=-=-=- */
  outlinesecondary:css`
    color: ${props =>  props.theme.colors.secondary};
    border-color: ${props =>  props.theme.colors.secondary};
    background-color: rgba(255,255,255,0.75);

    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: #fff;
      background-color: ${props =>  lighten(0.1, props.theme.colors.secondary)};
      border-color: ${props =>  darken(0.1, props.theme.colors.secondary)};
    }

    &:disabled,
    &.disabled {
      color: ${props =>  lighten(0.2, props.theme.colors.secondary)};
      border-color: ${props =>  lighten(0.2, props.theme.colors.secondary)};
      background-color: transparent;
    }

    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 0.3rem ${props =>  lighten(0.25, props.theme.colors.secondary)};
    }

    svg {
      height: 16px;
      width: auto;
      margin-left: 0.8rem;
      stroke: ${props =>  props.theme.colors.secondary};
      transition: stroke 0.15s ease-in-out;
    }

    &:hover svg,
    &:not(:disabled):not(.disabled):active svg,
    &:not(:disabled):not(.disabled).active svg {
      stroke: #fff;
    }

    .button-text {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }
  `,
  /* -=-=-=-=-=-=-=- */
  tertiary:css`
    color: #fff;
    background-color: ${props =>  darken(0.1, props.theme.colors.tertiary)};
    border-color: ${props =>  darken(0.1, props.theme.colors.tertiary)};

    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: #fff;
      background-color: ${props => lighten(0.05, props.theme.colors.tertiary)};
      border-color: ${props => props.theme.colors.tertiary};
    }

    &:disabled,
    &.disabled {
      color: #fff;
      background-color: ${props => lighten(0.1, props.theme.colors.tertiary)};
      border-color: ${props => lighten(0.1, props.theme.colors.tertiary)};
    }

    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 0.3rem ${props =>  lighten(0.15, props.theme.colors.tertiary)};
    }
  `,
  /* -=-=-=-=-=-=-=- */
  light:css`
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;

    &:hover {
      color: #212529;
      background-color: #e2e6ea;
      border-color: #dae0e5;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
    }

    &:disabled,
    &.disabled {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: #212529;
      background-color: #dae0e5;
      border-color: #d3d9df;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
    }
  `,
  /* -=-=-=-=-=-=-=- */
  info:css`
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;

    &:hover {
      color: #fff;
      background-color: #138496;
      border-color: #117a8b;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
    }

    &:disabled,
    &.disabled {
      color: #fff;
      background-color: #17a2b8;
      border-color: #17a2b8;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: #fff;
      background-color: #117a8b;
      border-color: #10707f;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
    }
  `,
  /* -=-=-=-=-=-=-=- */
  warning:css`
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;

    &&:hover {
      color: #212529;
      background-color: #e0a800;
      border-color: #d39e00;
    }

    &&:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
    }

    &&:disabled,
    &.disabled {
      color: #212529;
      background-color: #ffc107;
      border-color: #ffc107;
    }

    &&:not(:disabled):not(.disabled):active,
    &&:not(:disabled):not(.disabled).active {
      color: #212529;
      background-color: #d39e00;
      border-color: #c69500;
    }

    &&:not(:disabled):not(.disabled):active:focus,
    &&:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
    }
  `,
  /* -=-=-=-=-=-=-=- */
  danger:css`
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;

    &:hover {
      color: #fff;
      background-color: #c82333;
      border-color: #bd2130;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
    }

    &:disabled,
    &.disabled {
      color: #fff;
      background-color: #dc3545;
      border-color: #dc3545;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: #fff;
      background-color: #bd2130;
      border-color: #b21f2d;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
    }
  `,
  /* -=-=-=-=-=-=-=- */
    success:css`
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;

    &:hover {
      color: #fff;
      background-color: #218838;
      border-color: #1e7e34;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
    }

    &:disabled,
    &.disabled {
      color: #fff;
      background-color: #28a745;
      border-color: #28a745;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: #fff;
      background-color: #1e7e34;
      border-color: #1c7430;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
    }
  `,
  /* -=-=-=-=-=-=-=- */
  cta_yellow:css`
    color: #444;
    background-color: hsla(52, 100%, 55%, 1.000);
    border-color: hsla(52, 100%, 55%, 1.000);
    &:hover {
      color: #666;
      background-color: hsla(52, 100%, 65%, 1.000);
      border-color: hsla(52, 100%, 50%, 1.000);
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.5rem hsla(52, 100%, 55%, 0.3);
    }
    &:disabled,
    &.disabled {
      color: #fff;
      background-color: #ccc;
      border-color: #ccc;
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: #222;
      background-color: hsla(52, 100%, 65%, 1.000);
      border-color: hsla(52, 100%, 50%, 1.000);
    }
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 0.5rem hsla(52, 100%, 55%, 0.3);
    }
    svg {
      height: 16px;
      width: auto;
      margin-left: 0.8rem;
      stroke: #444;
    }
    .button-text {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }
  `,
  cta_blue_green:css`
    color: hsla(205,62%,30%,1);
    background-color: hsla(155, 80%, 70%, 1.000);
    border-color: hsla(155, 80%, 70%, 1.000);

    &:hover {
      color: hsla(205,62%,40%,1);
      background-color: hsla(155, 80%, 80%, 1.000);
      border-color: hsla(155, 80%, 60%, 1.000);
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 1rem hsla(155, 80%, 70%, 0.5);
    }

    &:disabled,
    &.disabled {
      color: #fff;
      background-color: #ccc;
      border-color: #ccc;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: hsla(205,62%,40%,1);
      background-color: hsla(155, 80%, 80%, 1.000);
      border-color: hsla(155, 80%, 60%, 1.000);
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 1rem hsla(155, 80%, 70%, 0.5);
    }
  `,
  /* -=-=-=-=-=-=-=- */
  cta_blue:css`
    font-size: 2.4rem;
    font-weight: 600;
    color: #fff;
    background-color: hsla(206, 71%, 33%, 1.000);
    border-color: hsla(206, 71%, 33%, 1.000);
    &:hover {
      color: hsla(206,71%,95%,1.000);
      background-color: hsla(206, 71%, 43%, 1.000);
      border-color: hsla(206, 71%, 43%, 1.000);
      box-shadow: 0 0 0 0.3rem hsla(180,80%,60%,1.000);
    }

    &:disabled,
    &.disabled {
      color: #fff;
      background-color: #ccc;
      border-color: #ccc;
    }
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      color: hsla(206, 71%, 28%, 1.000);
      background-color: hsla(180,100%,80%,1.000);
      border-color: hsla(180,100%,80%,1.000);
      box-shadow: 0 0 0 1rem hsla(212, 50%, 84%, 0.5);
    }
    svg {
      height: 16px;
      width: auto;
      margin-left: 0.8rem;
      stroke: #fff;
    }
    .button-text {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }
  `,
    /* -=-=-=-=-=-=-=- */
    gray_hollow:css`
      color: #000;
      background-color: #efefef;
      border-color: #ccc;
      font-weight: normal;

    &:hover {
      background-color: rgba(100,100,100,0.1);
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem #888;
    }
  `,
  /* -=-=-=-=-=-=-=- */
  color_btn:css`
    font-size: 2.4rem;
    font-weight: 600;
    color: #fff;
    &.color-0 {
      background-color: ${props =>  props.theme.homeColors.color0};
      border-color: ${props =>  props.theme.homeColors.color0};
    }
    &.color-1 {
      background-color: ${props =>  props.theme.homeColors.color1};
      border-color: ${props =>  props.theme.homeColors.color1};
    }
    &.color-2 {
      background-color: ${props =>  props.theme.homeColors.color2};
      border-color: ${props =>  props.theme.homeColors.color2};
    }
    &.color-3 {
      background-color: ${props =>  props.theme.homeColors.color3};
      border-color: ${props =>  props.theme.homeColors.color3};
    }

    &.color-0:hover {
      background-color: ${props =>  props.theme.homeColors.color0};
      border-color: ${props =>  props.theme.homeColors.color0};
      box-shadow: 0 0 0 0.3rem ${props =>  lighten(0.4, props.theme.homeColors.color0)};
    }
    &.color-1:hover {
      background-color: ${props =>  props.theme.homeColors.color1};
      border-color: ${props =>  props.theme.homeColors.color1};
      box-shadow: 0 0 0 0.3rem ${props =>  lighten(0.4, props.theme.homeColors.color1)};
    }
    &.color-2:hover {
      background-color: ${props =>  props.theme.homeColors.color2};
      border-color: ${props =>  props.theme.homeColors.color2};
      box-shadow: 0 0 0 0.3rem ${props =>  lighten(0.4, props.theme.homeColors.color2)};
    }
    &.color-3:hover {
      background-color: ${props =>  props.theme.homeColors.color3};
      border-color: ${props =>  props.theme.homeColors.color3};
      box-shadow: 0 0 0 0.3rem ${props =>  lighten(0.4, props.theme.homeColors.color3)};
    }
    &:hover {
      color: #fff
    }

    &.active.color-0 {
      color: ${props =>  props.theme.homeColors.color0};
      background-color: #fff;
    }
    &.active.color-1 {
      color: ${props =>  props.theme.homeColors.color1};
      background-color: #fff;
    }
    &.active.color-2 {
      color: ${props =>  props.theme.homeColors.color2};
      background-color: #fff;
    }
    &.active.color-3 {
      color: ${props =>  props.theme.homeColors.color3};
      background-color: #fff;
    }

    &.active.color-0,
    &.active.color-1,
    &.active.color-2,
    &.active.color-3,
    &.active.color-0:hover,
    &.active.color-1:hover,
    &.active.color-2:hover,
    &.active.color-3:hover {
      box-shadow: none;
    }

    &:disabled,
    &.disabled {
      color: #fff;
      background-color: #ccc;
      border-color: #ccc;
    }
    svg {
      height: 16px;
      width: auto;
      margin-left: 0.8rem;
      stroke: #fff;
    }
    .button-text {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }
  `,
  /* -=-=-=-=-=-=-=- */
  color_hollow_btn:css`
    font-size: 2.4rem;
    font-weight: 600;
    background-color: #fff;

    &.color-0 {
      color: ${props =>  props.theme.homeColors.color0};
      border-color: ${props =>  props.theme.homeColors.color0};
    }
    &.color-1 {
      color: ${props =>  props.theme.homeColors.color1};
      border-color: ${props =>  props.theme.homeColors.color1};
    }
    &.color-2 {
      color: ${props =>  props.theme.homeColors.color2};
      border-color: ${props =>  props.theme.homeColors.color2};
    }
    &.color-3 {
      color: ${props =>  props.theme.homeColors.color3};
      border-color: ${props =>  props.theme.homeColors.color3};
    }

    &.color-0:hover {
      color: #fff;
      background-color: ${props => props.theme.homeColors.color0};
      box-shadow: 0 0 0 0.3rem ${props =>  lighten(0.4, props.theme.homeColors.color0)};
    }
    &.color-1:hover {
      color: #fff;
      background-color: ${props => props.theme.homeColors.color1};
      box-shadow: 0 0 0 0.3rem ${props =>  lighten(0.4, props.theme.homeColors.color1)};
    }
    &.color-2:hover {
      color: #fff;
      background-color: ${props => props.theme.homeColors.color2};
      box-shadow: 0 0 0 0.3rem ${props =>  lighten(0.4, props.theme.homeColors.color2)};
    }
    &.color-3:hover {
      color: #fff;
      background-color: ${props => props.theme.homeColors.color3};
      box-shadow: 0 0 0 0.3rem ${props =>  lighten(0.4, props.theme.homeColors.color3)};
    }

    &:disabled,
    &.disabled {
      color: #fff;
      background-color: #ccc;
      border-color: #ccc;
    }

    svg {
      height: 16px;
      width: auto;
      margin-left: 0.8rem;
    }
    &.color-0 svg {
      stroke: ${props =>  props.theme.homeColors.color0};
    }
    &.color-1 svg {
      stroke: ${props =>  props.theme.homeColors.color1};
    }
    &.color-2 svg {
      stroke: ${props =>  props.theme.homeColors.color2};
    }
    &.color-3 svg {
      stroke: ${props =>  props.theme.homeColors.color3};
    }
    &.color-0:hover svg,
    &.color-1:hover svg,
    &.color-2:hover svg,
    &.color-3:hover svg {
      stroke: #fff;
    }
    .button-text {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }
  `,
};

/* size styling */
const sizes = {
  small:css`
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  `,
  medium:css`
    padding: 0.75rem 1.5rem;
    font-size: 1.75rem;
    line-height: 1.75;
    border-radius: 0.4rem;

    @media (max-width: ${BREAKPOINTS.TABLET}px) {
      padding: 0.5rem 1rem;
      font-size: 1.5rem;
      line-height: 1.5;
      border-radius: 0.3rem;
    }
  `,
  large:css`
    padding: 1rem 2rem;
    font-size: 2rem;
    line-height: 1.75;
    border-radius: 0.5rem;

    @media (max-width: ${BREAKPOINTS.TABLET}px) {
      padding: 0.75rem 1.5rem;
      font-size: 1.75rem;
      line-height: 1.75;
      border-radius: 0.4rem;
    }
  `,
  extralarge:css`
    padding: 1rem 2.5rem;
    font-size: 3rem;
    line-height: 2;
    border-radius: 0.6rem;

    @media (max-width: ${BREAKPOINTS.TABLET}px) {
      padding: 1rem 2rem;
      font-size: 2rem;
      line-height: 1.75;
      border-radius: 0.5rem;
    }
    @media (max-width: ${BREAKPOINTS.MOBILE_L}px) {
      padding: 0.75rem 1.5rem;
      font-size: 1.75rem;
      line-height: 1.75;
      border-radius: 0.4rem;
    }
  `,
}

/* defaults */
const defaults = css`
  appearance: none;
  cursor: pointer;
  overflow: hidden;

  /* from bootstrap */
  display: inline-block;
  position: relative;
  margin: 0;
  font-weight: 700;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    color: #212529;
    text-decoration: none;
  }
  &:focus,
  &.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  &:disabled,
  &.disabled {
    opacity: 0.65;
    pointer-events: none;
  }

  /* ripple effect */
  .ripple-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    left: 0;
    right: 0;
    width: 100%;
    padding-bottom: 100%;
    overflow: hidden;
    /* background: rgba(0,0,0,0.2); */
  }
  .ripple-container::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    transform: scale(0);
    border-radius: 50%;
    background-color: rgba(255,255,255,0.6);
  }
  &:hover .ripple-container::before {
    animation: ${btnRipple} 1000ms;
    animation-iteration-count: 1;
    animation-delay: 0;
    animation-timing-function: ease;
  }
`;

export const ButtonStyled = styled.button`
  ${defaults}
  ${props => types[props.buttontype]}
  ${props => sizes[props.buttonsize]}
`;

export const InternalLinkButtonStyled = styled(props => <Link {...props} />)`
  ${defaults}
  ${props => types[props.buttontype]}
  ${props => sizes[props.buttonsize]}
`;

export const AnchorLinkButtonStyled = styled(props => <AnchorLink {...props} />)`
  ${defaults}
  ${props => types[props.buttontype]}
  ${props => sizes[props.buttonsize]}
`;

