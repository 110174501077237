import React from 'react';
import AppProvider from 'store/provider';
import Cookies from 'universal-cookie';
import { trigPageview } from './src/helpers/tracking';
import Google  from './src/helpers/google';

// see here for details about this page's API:
// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/

const NO_REDIRECT_PAGES = [
  'audit',
  'risingratesrisingminds',
  '/demo/',
  '/callback/',
  '/setup/',
  '/cm-demo/'
];

const cookies = new Cookies();

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>;
};

// when app init
export const onClientEntry = async () => {
  Google.initGoogleTagManager();
};

// export const disableCorePrefetching = () => {
//   if (cookies.get('pp.login')) {
//     console.log('disableCorePrefetching')
//     return true;
//   }
// };

// Firefox has weird behavior that reloads page infinitely on redirect, putting redirect here with timeout fixed issue
export const onInitialClientRender = async () => {
  setTimeout(() => {
    const loc = window.location;
    if (
      !NO_REDIRECT_PAGES.some((v) => loc.pathname.includes(v)) &&
      !loc.href.includes('app.paperlesspipeline.com') &&
      cookies.get('pp.login')
    ) {
      window.location = 'https://app.paperlesspipeline.com/accounts/login/';
    }
  }, 500);
};

// when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation) {
    // don't trigger page view on inital load, need to init trackers if consent given
    trigPageview(location);
  }
};