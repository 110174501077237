import styled from 'styled-components';

export const GdprConsentUiStyles = styled.div`
  position: fixed;
  max-width: 370px;
  bottom: 1rem;
  right: 1rem;
  margin-left: 1rem;
  color: #333;
  z-index: 10; /* need 10 to overlay scroll btn on home page */
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light-color-0);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 50%);
  border-radius: 0.3rem;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  pointer-events: none;
  transition:
    opacity 200ms linear,
    max-height 250ms ease;

  &.show {
    pointer-events: all;
    opacity: 1;
    max-height: 500px;
  }

  .gdpr-wrap {
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem 0;
    padding: 1.5rem;
    max-width: 1000px;
  }

  p {
    font-size: 1.6rem;
    line-height: 1.4;
    max-width: unset;
    margin: 0;
    text-wrap: balance;
  }

  .btn-wrap {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    gap: 0 2rem;
    flex: 0 0 auto;
  }

  a {
    text-decoration: underline;
  }

  /* MEDIA QUERIES -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*- */
  /* @media (max-width: 450px) {} */
`;