import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider } from './createContext';
import DeviceData from '../helpers/deviceData';
import Debounce from '../helpers/debounce';
import { hasLoggedIn, storageAvailable } from '../helpers/hasLoggedIn';
import Stats from '../helpers/stats';
import Google from '../helpers/google';
import { useGdprConsent } from '../components/gdpr/gdpr-consent';

// window.addEventListener('load', () => {
//   var test = DeviceData.getDeviceDetails();
//   console.log(test);
// });

const initDeviceWatchers = (setDeviceData) => {
  const refreshDeviceDetails = Debounce(() => {
    setDeviceData(DeviceData.getDeviceDetails());
  }, 250);

  window.matchMedia('(orientation: portrait)').addListener((/*m*/) => {
    // if (m.matches) {
    //   console.log('Changed to portrait');
    // } else {
    //   console.log('Changed to landscape');
    // }
    refreshDeviceDetails();
  });

  window.addEventListener(
    'orientationchange',
    () => {
      refreshDeviceDetails();
    },
    false
  );

  window.addEventListener(
    'resize',
    () => {
      refreshDeviceDetails();
    },
    false
  );
};

const statsPlaceholder = {
  users: '0',
  closed_tx_millions: '0.0',
  transactions: 0,
  tx_closed_30_days: 0,
  company_sign_ups: 0,
};

const AppProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [color, setColor] = useState('color-0');
  const [deviceData, setDeviceData] = useState(DeviceData.getDeviceDetails());
  const [ppStats, setPpStats] = useState(statsPlaceholder);
  const { setShowConsentModal, gdprUi } = useGdprConsent();


  useEffect(() => {
    initDeviceWatchers(setDeviceData);
    const getStats = async () => {
      await Stats.getStats(setPpStats, true);
    };
    getStats();
  }, []);

  const providerObj = {
    open: showModal,
    showModal: () => setShowModal(true),
    hideModal: () => setShowModal(false),
    copyright: (
      <span className="copyright">
        &copy; {new Date().getFullYear()} Paperless Pipeline
      </span>
    ),
    localStorage: storageAvailable('localStorage'),
    hasLoggedIn: hasLoggedIn(),
    deviceData: deviceData,
    google: Google,
    trackEventGTM: Google.trackEventGTM,
    globalColor: color,
    setGlobalColor: setColor,
    ppStats: ppStats,
    gdprUi: gdprUi,
    setShowConsentModal: setShowConsentModal
  };

  return <Provider value={providerObj}>{children}</Provider>;
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
