import React, { useState, useEffect } from 'react';
import { GdprConsentUi } from './gdpr-consent-ui';
import { initTrackers, blockTrackers } from '../../helpers/tracking';

const CONSENT_LS_KEY = 'pipeline_consent_key';

const setConsentPref = (str) => {
  return window.localStorage?.setItem(CONSENT_LS_KEY, str);
};

const getConsentPref = () => {
  return window.localStorage?.getItem(CONSENT_LS_KEY);
};

export const useGdprConsent = () => {
  const [ consent, setConsent ] = useState(null);
  const [ showConsentModal, setShowConsentModal ] = useState(false);

  useEffect(() => {
    const storedConsentPref = getConsentPref();
    if (typeof storedConsentPref !== 'string') {
      setShowConsentModal(true);
    } else {
      setConsent(storedConsentPref);
    }
  }, []);

  useEffect(() => {
    if (consent === 'granted') {
      initTrackers();
    } else if (consent === 'denied') {
      blockTrackers();
    }
    typeof consent === 'string' && setConsentPref(consent);
  }, [ consent ]);

  const gdprUi =  <GdprConsentUi
    setConsent={ setConsent }
    showConsentModal={ showConsentModal }
    setShowConsentModal={ setShowConsentModal }
  />;

  return {
    gdprUi: gdprUi,
    showConsentModal: showConsentModal,
    setShowConsentModal: setShowConsentModal
  };
}