import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import * as Button from '../button/button';
import Context from '../../store/createContext';
import { GdprConsentUiStyles } from './gdpr-consent-ui.css';

export const GdprConsentUi = ({
  setConsent,
  showConsentModal,
  setShowConsentModal
}) => {
  const { globalColor } = useContext(Context);
  const btnClick = (evt) => {
    evt.currentTarget.dataset.consentPref && setConsent(evt.currentTarget.dataset.consentPref);
    setShowConsentModal(false);
  };

  return (
    <GdprConsentUiStyles className={`${ globalColor } ${ showConsentModal ? 'show' : ''}`} id="gdpr">

      <div className="gdpr-wrap">

        <p>We use cookies to improve your experience, analyze website traffic, and provide personalized ads and content. By clicking &quot;Accept&quot;, you agree to our use of cookies. Learn more in our <a href="/cookie-policy" target="_blank">cookie policy</a>.</p>

        <div className="btn-wrap">

          <Button.Gray
            className={ globalColor }
            text={<span className="button-text">Reject</span>}
            element='button'
            data-consent-pref="denied"
            onClick={btnClick}
            ripple={true}
            data-btn="decline"
            buttonSize={Button.SIZES.SMALL}
          />

          <Button.ColorBtn
            className={ globalColor }
            text={<span className="button-text">Accept</span>}
            element='button'
            data-consent-pref="granted"
            onClick={btnClick}
            ripple={true}
            data-btn="accept"
            buttonSize={Button.SIZES.SMALL}
          />

        </div>

      </div>

    </GdprConsentUiStyles>
  );
};

GdprConsentUi.propTypes = {
  setConsent: PropTypes.func.isRequired,
  showConsentModal: PropTypes.bool.isRequired,
  setShowConsentModal: PropTypes.func.isRequired
};